@font-face {
    font-family: 'Segoe-UI';
    src: local('Segoe-UI'), url(./Segoe-UI.ttf) format('truetype');
}

@font-face {
    font-family: 'Segoe-UI-Bold';
    src: local('Segoe-UI-Bold'), url(./Segoe-UI-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Segoe-UI-Bold-Italic';
    src: local('Segoe-UI-Bold-Italic'), url(./Segoe-UI-Bold-Italic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Segoe-UI-Italic';
    src: local('Segoe-UI-Italic'), url(./Segoe-UI-Italic.ttf) format('truetype');    
    font-style: italic;
}
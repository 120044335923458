@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway-Bold';
    src: local('Raleway-Bold'), url(./Raleway-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'Raleway-Bold-Italic';
    src: local('Raleway-Bold-Italic'), url(./Raleway-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Raleway-Italic';
    src: local('Raleway-Italic'), url(./Raleway-Italic.ttf) format('truetype');    
    font-style: italic;
}